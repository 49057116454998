import React, { useState, useEffect } from 'react';
import './App.css';
import './firebase';
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();


function App() {
  useEffect(() => {
    logEvent(analytics, 'Visited the website');
  }, []);

  const reasons = [
    "My cat advised against it",
    "Heard the moon was too crowded",
    "The coins were too heavy for my digital wallet",
    "Couldn't find a Bitcoin tree to replenish my stock",
    "Sold them to buy a lifetime supply of pizza",
    "Aliens told me fiat is the next big thing",
    "My horoscope warned me about virtual gold",
    "I'm investing in my chocolate coin collection",
    "Wanted to free up some space on the internet",
    "Planning to start my own coin: CouchCoin",
    "I dreamt that Satoshi told me to sell",
    "Sold them to build a time machine",
    "I'm afraid of heights, and Bitcoin was too high",
    "I’m actually a pirate and prefer treasure chests",
    "My psychic said it’s bad Feng Shui",
    "I'm trading them for magic beans",
    "I can't swim and Bitcoin was in deep waters",
    "Trying to cut back on screen time",
    "It was intimidating my other investments",
    "Sold them for a ticket to Narnia",
    "I heard winter is coming in the crypto world",
    "Making room for my Beanie Babies collection",
    "I only invest in things I can draw",
    "My fortune cookie didn’t mention Bitcoin",
    "I’m diversifying into invisible assets",
    "Sold them to start my career in sandcastle building",
    "I’m more of a paper wallet person",
    "I prefer collecting stamps",
    "Planning to buy a unicorn farm",
    "I’m switching to a career in ghost hunting",
    "Bitcoin didn’t match my outfit",
    "Sold them to buy a pet dragon",
    "I'm investing in antique ice cube trays",
    "The blockchain was too blocky for my taste",
    "I was scared of the Bitcoin rollercoaster",
    "I’m old school and prefer carrier pigeons",
    "Sold them for a lifetime supply of avocado toast",
    "I’m investing in my dream of becoming a mermaid",
    "It’s a leap year, seemed like the right time",
    "I traded them for a pirate ship",
    "I’m saving up for a time travel machine",
    "I'm going all-in on sea shells",
    "Sold them to fund my rock collection",
    "I’m betting on the underdog: VHS tapes",
    "I’m funding my quest to find Atlantis",
    "Heard the moon landing was faked",
    "Sold them to invest in my psychic's startup",
    "I lost a bet to a leprechaun",
    "I’m training for the snail racing championship",
    "I'm diversifying into dream catchers",
    "I need to finance my unicorn spotting expedition",
    "I’m investing in a startup: teleportation",
    "Heard the Matrix is looking for investors",
    "Sold them to join a professional hopscotch league",
    "I’m saving up to build a chocolate factory",
    "I’m more interested in investing in fairy dust",
    "Sold them to start a cloud collection",
    "I prefer assets I can knit",
    "I’m stocking up on wishes from wishing wells",
    "I’m switching to a career in dragon training",
    "I traded them for a map to El Dorado",
    "I’m buying a ticket to Hogwarts",
    "I need funds to build a spaceship",
    "I’m investing in my ability to talk to plants",
    "Sold them for an advanced course in alchemy",
    "I’m saving up for a new broomstick",
    "I’m investing in a giant beanstalk",
    "I’m funding my search for the Fountain of Youth",
    "Heard Santa Claus is accepting investments",
    "I’m buying stocks in the Tooth Fairy’s company",
    "I’m backing a startup: leprechaun gold mining",
    "Sold them to invest in candy currency",
    "I’m purchasing a magic carpet",
    "I'm investing in my new hobby: cloud shaping",
    "Sold them to finance my superhero career",
    "I’m buying a plot of land in Wonderland",
    "I’m investing in a crystal ball",
    "I’m funding my expedition to the North Pole",
    "Sold them to build a castle in the sky",
    "I'm swapping them for a philosopher’s stone",
    "I’m investing in pixie dust startups",
    "I’m saving up to buy a rainbow",
    "I’m investing in a secret unicorn startup",
    "I’m buying shares in a magic wand factory",
    "I need capital for my genie lamp polishing business",
    "I’m acquiring a collection of magic mirrors",
    "I’m investing in a pumpkin carriage company",
    "I’m saving up for a trip to Neverland",
    "I’m building a portfolio of enchanted artifacts",
    "I’m diversifying into wizardry",
    "I’m funding my quest for a golden fleece",
    "I’m investing in a fairy godmother consultancy",
    "I’m starting a business in potion brewing",
    "I’m buying a lifetime supply of lucky charms",
    "Sold them to become a professional unicorn whisperer",
    "I’m investing in an invisibility cloak venture",
    "I’m saving to build a house made of candy",
    "I traded my Bitcoin because it never fetched the ball like a good pet rock should.",
    "Sold my Bitcoin – turns out, it's less interactive than a pet rock.",
    "Decided to go for a real pet rock, it's more tactile than Bitcoin.",
    "My Bitcoin didn't make a good paperweight, unlike my trusty pet rock.",
    "I swapped Bitcoin for a pet rock; at least the rock doesn’t need a password.",
    "Bitcoin wasn’t as fun to talk to as my silent yet wise pet rock.",
    "I found a pet rock that promises better returns than Bitcoin.",
    "Unlike Bitcoin, my pet rock provides steady, unchanging support.",
    "Traded Bitcoin for a pet rock. It's a more grounded investment.",
    "My pet rock criticizes my life choices less than Bitcoin."
  ];

  const causes = [
    "Too many ups and downs for my delicate tummy!",
    "My fragile stomach can't dance to this volatile tune!",
    "This volatility is rocking my boat and my queasy stomach!",
    "My sensitive belly can't digest these wild market swings!",
    "This financial rollercoaster is too much for my tender tummy!",
    "Such market turbulence! My weak stomach is waving a white flag!",
    "My stomach’s doing flips with all these volatile moves!",
    "This erratic market is giving my delicate stomach the jitters!",
    "My poor tummy can't stomach this unpredictable ride!",
    "Market swings like these are a no-go for my queasy belly!"
  ]
  // Function to get a random reason
  const getRandomReason = () => {
    return reasons[Math.floor(Math.random() * reasons.length)];
  };

  const getRandomCauses = () => {
    return causes[Math.floor(Math.random() * causes.length)];
  };

  // State to store the selected reason
  const [reason, setReason] = useState(getRandomReason());

  // State to store the selected reason
  const [cause, setCause] = useState(getRandomCauses());

  // Function to update reason
  const changeReason = () => {
    logEvent(analytics, 'Clicked on "Press for Bitcoin Drama" button');
    setReason(getRandomReason());
    setCause(getRandomCauses());
  };

  // ... rest of your component ...

  return (
    <div className="App">
      <header className="App-header">
        <h4>I sold my Bitcoin because it has no intrinsic value.</h4>
        <p>Also, {reason}</p>
        <img src="/sold-my-btc.png" alt="Cartoon character avoiding the gym" />
        <button onClick={changeReason}>Press for Bitcoin Drama</button>
        <footer>AVL - {cause}</footer>
      </header>
    </div>
  );
}

export default App;
