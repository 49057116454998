// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC19QIBi9xdL4l3AuyA0k6oTqhTFZzjpK8",
  authDomain: "i-sold-my-bitcoin-because.firebaseapp.com",
  projectId: "i-sold-my-bitcoin-because",
  storageBucket: "i-sold-my-bitcoin-because.appspot.com",
  messagingSenderId: "304751852096",
  appId: "1:304751852096:web:8d18c48a7dc33ce1093da5",
  measurementId: "G-DKNHLBCTV0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);